<template>
  <main class="Routes">
    <TheSubHeader
      icon="rote"
      title="Rotas"
      page-title-tag="Routes"
      addnew-link="/routes/add"
      type="filter"
      :actions="['addnew']"
      organizer-gap="1rem"
      organizer-columns="220px 1fr 1fr 1fr 1fr 1fr 1fr auto"
    >
      <template #beforeButtons>
        <BaseButton
          v-if="buttonsPermissions.routEasySync"
          icon="rote"
          color="dark"
          label="RoutEasy"
          :track="trackSubheaderButtonString('Routes', 'RoutEasy')"
          @click="$router.push('/routes/routeasy/sync')"
        />
        <BaseButton
          v-if="buttonsPermissions.sortingOrders"
          icon="status-alt"
          color="dark"
          label="Triagem"
          :track="trackSubheaderButtonString('Routes', 'Sorting Orders')"
          @click="$refs.SortingOrders.open()"
        />
        <BaseButton
          v-if="buttonsPermissions.hub"
          icon="address"
          color="dark"
          label="Controle de HUB"
          :track="trackSubheaderButtonString('Routes', 'Hubs')"
          @click="$router.push('/routes/hub')"
        />
      </template>
      <template #filter>
        <!-- Period -->
        <BaseDatePicker
          v-model="formattedDateRange"
          label="Intervalo entre datas (max. 15 dias)"
          :max-range="15"
        />

        <!-- Store -->
        <BaseTreeSelect
          ref="lojista"
          v-model="filters.storeLabel"
          name="Ponto de Coleta"
          label="Ponto de Coleta"
          placeholder="Selecione"
          :select-options="null"
          :fetch-options="'routes/fetchTransitPointImportAsync'"
        />

        <!-- Route Title -->
        <BaseTreeSelect
          v-model="filters.searchValue"
          name="Título da Rota"
          label="Título da Rota"
          placeholder="Pesquisar"
          multiple
          suggestions="searchRoutes"
          :min-search-length="2"
          @input="updateTags()"
        />
        <!-- Orders -->
        <BaseTreeSelect
          ref="query"
          v-model="filters.query"
          name="Pedido"
          label="Pedido"
          placeholder="Pesquisar"
          multiple
          :select-all="false"
          :enable-paste="true"
          :allow-create="true"
          :disable-branch-nodes="true"
          @input="updateTags()"
        />
        <!-- Driver -->
        <BaseTreeSelect
          v-model="filters.driversId"
          name="Motorista"
          label="Motorista"
          placeholder="Selecione"
          multiple
          suggestions="searchDrivers"
          @input="updateTags()"
        />
        <!-- Route -->
        <BaseTreeSelect
          v-model="filters.driveSelected"
          name="Rotas"
          label="Rotas"
          placeholder="Selecione"
          :select-options="drivesOpt"
          @input="parseDrivers(), updateTags()"
        />
        <!-- insured route -->
        <BaseTreeSelect
          v-model="filters.insuredRoute"
          name="Rotas asseguradas"
          label="Rotas asseguradas"
          placeholder="Selecione"
          :select-options="insuredOpt"
          @input="search"
        />
        <!-- Filter -->
        <BaseButton
          label="Filtrar"
          theme="icon-left"
          color="primary-base"
          filled
          icon="filter"
          text-color="white"
          @click="resetSearch()"
        />
      </template>
      <template v-if="allTags.length" #tag>
        <div class="Orders__tags">
          <FiltersTag :tags="allTags" @removeTag="removeTag" @clear="clearAllTags" />
        </div>
      </template>
    </TheSubHeader>
    <div class="Routes__content">
      <div v-if="firstSearch" class="Routes__table">
        <DataTable
          :header="routesWithDriverActions.data.headers"
          :table="routesWithDriverActions.data.content"
          is-sticky
          @edit="editRoute($event), trackTableAction('Edit', $event)"
          @remove="removeRoute($event), trackTableAction('Remove', $event)"
          @print="printRoute($event), trackTableAction('Print Route', $event)"
          @upload="uploadRoute($event), trackTableAction('Upload Route', $event)"
          @notify="notifyRoute($event), trackTableAction('Notify Route', $event)"
          @linkDriver="openLinkDriver($event), trackTableAction('Link Driver', $event)"
          @unlinkDriver="openUnlinkDriver($event), trackTableAction('Unlink Driver', $event)"
          @showStoresRoute="previewRouteStores($event), trackTableAction('Show Route Stores', $event)"
          @history="handleHistory($event), trackTableAction('Show Route History', $event)"
          @seeErrors="handleSeeErrors($event), trackTableAction('Show Route Errors', $event)"
        />
        <Pagination @page="search()" @paginate="search()">
          <div class="Routes__captions">
            <div v-for="item in captions" :key="item.color" class="Routes__captions--item">
              <span class="circle" :class="`circle-${item.color}`" />
              {{ item.label }}
            </div>
          </div>
        </Pagination>
      </div>
      <div v-else class="Routes__firstSearch">
        <p>Utilize o filtro para exibir as rotas.</p>
      </div>
    </div>
    <Dialog
      v-if="show"
      :warning="warningMessage"
      label-cancel="Não"
      label-confirm="Sim"
      @confirm="confirmDelete"
      @cancel="cancel"
    />
    <Dialog
      v-if="simpleDialog"
      label-confirm="Confirmar"
      warning="Romaneio enviado com sucesso"
      label-cancel=""
      @confirm="confirmSimple"
    />

    <TheRouteStores v-if="editRoutes" :id="id" :store="storeName" :transporter-id="transporterId" :list="routesStores" :route-info="info" @close="closeEdit" />

    <Dialog
      v-if="showUnlinkDriver"
      warning="Tem certeza que deseja desvincular o motorista dessa rota?"
      label-confirm="Sim"
      label-cancel="Não"
      @confirm="confirmUnlink"
      @cancel="cancelUnlink"
    />
    <Modal v-if="showLinkDriver" class="Routes__Modal" @close="cancelLink">
      <template #header>
        <div class="Routes__ModalHeader">
          <span>Vincular motorista à rota: <strong>{{ routeName }}</strong></span>
        </div>
      </template>
      <template #body>
        <div class="Routes__ModalBody">
          <!-- Driver -->
          <BaseTreeSelect
            v-model="driverToLink"
            class="searchDrivers"
            name="Motorista"
            label="Motorista"
            placeholder="Pesquisar"
            has-alert
            :suggestions="searchDriverSuggestions"
            :suggestions-params="searchDriverSuggestionsParams"
          />

          <BaseButton
            label="Vincular"
            theme="icon-left"
            filled
            color="primary-base"
            text-color="white"
            :disabled="driverToLink === null"
            @click="linkDriver"
          />
        </div>
      </template>
    </Modal>
    <Modal v-if="showPreviewRouteStores" class="Routes__Modal" @close="showPreviewRouteStores = false">
      <template #header>
        <div class="Routes__ModalHeader">
          <span>Lojistas na rota <strong>{{ showPreviewRouteName }}</strong>:</span>
        </div>
      </template>
      <template #body>
        <div class="Routes__ModalBody">
          <DataTable
            :header="showPreviewRouteStoresTable.data.headers"
            :table="showPreviewRouteStoresTable.data.content"
          />
        </div>
      </template>
    </Modal>

    <!-- Routes History -->
    <RoutesHistory ref="RoutesHistory" @close="handleCloseModal" />

    <!-- Routes Errors -->
    <RoutesErrors ref="RoutesErrors" />

    <!-- Sorting Orders -->
    <SortingOrders ref="SortingOrders" />
  </main>
</template>

<script>
import { TheSubHeader, TheRouteStores } from '@/components/organisms'
import { BaseTreeSelect, BaseButton, BaseDatePicker } from '@/components/atoms'
import { Pagination, Dialog, DataTable, FiltersTag, Modal } from '@/components/molecules'
import { routes } from '@/api'
import { mapActions, mapState, mapGetters} from 'vuex'

import RoutesHistory from '@/views/routes/RoutesHistory'
import RoutesErrors from '@/views/routes/RoutesErrors'
import SortingOrders from '@/views/routes/SortingOrders'

export default {
  name: 'OrdersImport',
  components: {
    TheSubHeader,
    BaseDatePicker,
    BaseTreeSelect,
    BaseButton,
    DataTable,
    Pagination,
    Dialog,
    TheRouteStores,
    FiltersTag,
    Modal,
    RoutesHistory,
    RoutesErrors,
    SortingOrders
  },
  data() {
    return {
      filters: {
        driversId: [],
        dateRange: {
          init: null,
          end: null,
        },
        searchValue: [],
        query: [],
        storeLabel: null,
        parsedDrivers: null,
        romaneioSelected: {},
        driveSelected: null,
        insuredRoute: null
      },
      allTags: [],
      simpleDialog: false,
      show: false,
      warningMessage: '',
      dialogTrigger: undefined,
      id: {},
      storeName: '',
      transporterId: null,
      editRoutes: false,
      drivesOpt: [{ id: 0, name:'Todos' },{ id: 1, name:'Atribuído' }, { id: 2, name:'Não atribuído' }],
      insuredOpt: [{ id: 2, name: 'Apólice ativa'}, { id: 4, name: 'Apólice concluída'}, { id: 5, name: 'Criação pendente'}],
      romaneiosOpt: [{ id:true , name:'Enviado' }, { id: false, name:'Não enviado' }],
      info: '',
      firstSearch: false,
      driverToLink: null,
      driverToUnlink: null,
      showUnlinkDriver: false,
      showLinkDriver: false,
      routeName: null,
      routeId: null,
      routeData: null,
      showPreviewRouteStores: false,
      showPreviewRouteName: '',
      showPreviewRouteStoresTable: {
        data: {
          headers: {
            'id':{
              'id':'id',
              'param':'id',
              'label':'Id',
              'method':false,
              'isVisible':false
            },
            'name':{
              'id':'name',
              'param':'name',
              'label':'Lojista',
              'method':false,
              'isVisible':true
            },
            actions: {}
          },
          content: []
        }
      },

      //Helpers
      updating: false,
      time: null,
      timeOutUpdate: 10 * 1000,

      captions: [
        { label: 'motorista atribuído', color: 'green' },
        { label: 'motorista não atribuído', color: 'grey' }
      ]
    }
  },
  computed:{
    ...mapState({
      transitPointImport: state => state.routes.transitPointImport,
      routes: state => state.routes.routes,
      routesStores: state => state.routes.routesStores,
      queryString: state => state.queryString.queryString,
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate,
      permissions: state => state.auth.permissions
    }),

    ...mapGetters({
      routesWithDriverActions: 'routes/routesWithDriverActions',
    }),

    //Permissios Buttons
    buttonsPermissions() {
      return {
        routEasySync: this.permissions?.routes?.routEasySyncPageButton,
        transfer: this.permissions?.routesTransfer?.isVisible,
        cte: this.permissions?.cteManagement?.isVisible,
        hub: this.permissions?.routes?.controlButton,
        sortingOrders: this.permissions?.sortingButton?.isVisible
      }
    },
    dateRangeInit() {
      const days = 15
      const dateOffset = (24*60*60*1000) * days
      const calculatedDate = new Date()
      calculatedDate.setTime(calculatedDate.getTime() - dateOffset)
      return calculatedDate.getTime()
    },

    dateRangeEnd() {
      const calculatedDate = new Date()
      const dateNow = new Date(calculatedDate)
      return dateNow.getTime()
    },

    searchDriverSuggestions() {
      return this.routeData && this.routeData['store.name'] === '-'
        ? 'searchDriversWithTransitPoint'
        : 'searchDriversWithStores'
    },

    searchDriverSuggestionsParams() {
      return this.routeData && this.routeData['store.name'] === '-'
        ? this.routeData && this.routeData.transit_id
        : this.routeData && this.routeData.store_id
    },

    formattedDateRange: {
      get: function() {
        return [this.filters.dateRange.init, this.filters.dateRange.end]
      },
      set: function(newValue) {
        this.filters.dateRange.init = newValue[0]
        this.filters.dateRange.end = newValue[1]
      },
    },

  },
  mounted() {
    this.setInitialDate()
  },
  methods: {
    ...mapActions({
      fetchTransitPointImport: 'routes/fetchTransitPointImport',
      fetchRoutes: 'routes/fetchRoutes',
      sendRomaneio: 'routes/sendRomaneio',
      fetchRoutesStores: 'routes/fetchRoutesStores',
      clearRoutesStores: 'routes/clearRoutesStores',
      deleteRoute: 'routes/deleteRoute',
      setLoading: 'loading/setLoading',
      setParameters: 'queryString/setParameters',
      clearPagination: 'pagination/clearPagination',
      setPagination: 'pagination/setPagination',
      bindDriver: 'routes/bindDriverWithoutMutation',
      setError: 'error/setError'
    }),

    trackTableAction(tagName, data) {
      this.$mixpanel.track(`Routes - Clicked on ${tagName} Table Action`, data)
    },

    setInitialDate() {
      this.$set(this.filters.dateRange, 'init', this.dateRangeInit)
      this.$set(this.filters.dateRange, 'end', this.dateRangeEnd)
    },

    parseDrivers(){
      const driverId = this.filters.driveSelected ? this.filters.driveSelected.id : 0
      switch (driverId) {
      case 0:
        this.filters.parsedDrivers = undefined
        break
      case 1:
        this.filters.parsedDrivers = '!='
        break
      case 2:
        this.filters.parsedDrivers = '='
        break
      default:
        this.filters.parsedDrivers = undefined
        break
      }

      this.updateTags()

      // this.resetSearch()
    },

    updateTags(){
      this.allTags = [...this.filters.driversId, ...this.filters.searchValue, ...this.filters.query ]
      if (this.filters.storeLabel) { this.allTags.push(this.filters.storeLabel) }
    },

    removeTag(name, id){
      this.allTags = this.allTags.filter((tag) => tag.id !== id)
      this.filters.searchValue = this.filters.searchValue.filter((tag) => tag.name !== name)
      this.filters.query = this.filters.query.filter((tag) => tag.name !== name)
      this.filters.storeLabel = this.filters.storeLabel.name === name ? null : this.filters.storeLabel
      this.filters.driversId = this.filters.driversId.filter((tag) => tag.name !== name)

      // this.resetSearch()
    },

    clearAllTags(){
      this.allTags = []
      this.filters.searchValue = []
      this.filters.query = []
      this.filters.storeLabel = null
      this.filters.driversId = []

      // this.resetSearch()
    },

    resetSearch() {
      this.clearPagination(this.paginate).then(() => this.search())
    },

    search(loading = true){
      this.firstSearch = true
      const params = {
        obj: {
          driver_id: this.filters.driversId,
          date_start: this.filters.dateRange.init,
          date_end: this.filters.dateRange.end,
          id: this.filters.searchValue,
          'filter[orders][order_number]': this.filters.query,
          ['driver_id[signal]']: this.filters.parsedDrivers,
          ['driver_id[value]']: this.filters.parsedDrivers ? 'null' : undefined,
          'filter[driverInsurance][insurance_status_id]': this.filters.insuredRoute,
          romaneio: this.filters.romaneioSelected.id,
        },
        paginate: this.paginate,
        page: this.page
      }

      if (this.filters.storeLabel && this.filters.storeLabel.schedule) {
        const id = this.filters.storeLabel.id.replace('H-', '')
        let result = {
          'transit_point_id': id,
        }
        params.obj = {...result}
      } else if (this.filters.storeLabel) {
        let result = {'store_id': this.filters.storeLabel}
        params.obj = {...result}
      }

      this.updateTags()
      this.setParameters(this.filters)

      //
      if (loading) {
        this.setLoading(true)
      }
      this.fetchRoutes(params).then(() => {
        this.setPagination(this.routesWithDriverActions)
        this.checkLoadingAction(this.routesWithDriverActions)
      })
    },

    checkLoadingAction(table) {
      let hasLoadingAction = table?.data?.content.some((el) => el.actions.some(action => action.action_id === 'loading' && action.has_permission === true))
      if (hasLoadingAction && this.$route.name === 'routes') {
        if (!this.updating) {
          this.updating = true
          this.time = setTimeout(() => {
            this.search(false)
            this.updating = false
          }, this.timeOutUpdate + this.time)
          this.setPagination(this.routesWithDriverActions)
        }
      } else {
        clearTimeout(this.time)
        this.setPagination(this.routesWithDriverActions)
      }
    },

    removeRoute(data){
      this.warningMessage = 'Tem certeza que deseja deletar essa rota?'
      this.dialogTrigger = 'delete'
      this.id = data.id
      this.show = true
    },

    uploadRoute(data){
      this.warningMessage = 'Tem certeza que deseja enviar o Romaneio para essa rota?'
      this.dialogTrigger = 'upload'
      this.id = data.id
      this.show = true
    },

    notifyRoute(id){
      this.warningMessage = 'Tem certeza que deseja enviar essa rota?'
      this.dialogTrigger = 'notify'
      this.id = id
      this.show = true
    },

    async editRoute(data){
      this.setLoading(true)
      await this.fetchRoutesStores(data.id)
      this.id = data.id
      this.transporterId = data.route_transfer_transporter_id
      this.storeName = data['store_id'] || data['store.id'] ? data['store.name'] : data['transit_name']
      this.storeName = this.storeName === null ? '' : this.storeName
      this.info = data
      this.editRoutes = true
      this.setLoading(false)
    },

    async printRoute(data){
      this.setLoading(true)
      await routes.printRoute(data.id, (r) => r, (e) => e, () => this.setLoading(false))
    },

    closeEdit(){
      this.clearRoutesStores()
      this.search()
      this.editRoutes = false
    },

    async confirmDelete(){
      switch (this.dialogTrigger) {
      case 'delete':
        this.deleteRoute(this.id)
        break
      case 'upload':
        var romaneioRes = await this.sendRomaneio(this.id)
        if(romaneioRes) this.simpleDialog = true
        break
      case 'notify':
        var notificationRes = await this.sendNotification(this.id)
        if(notificationRes) this.simpleDialog = true
        break
      default:
        break
      }

      this.show = false
    },

    handleHistory(data) {
      this.$refs.RoutesHistory?.open(data)
    },

    handleSeeErrors(data) {
      this.$refs.RoutesErrors?.open(data)
    },

    handleCloseModal() {
      this.setPagination(this.routesWithDriverActions)
    },

    cancel(){
      this.show = false
    },

    confirmSimple(){
      this.simpleDialog = false
    },

    openLinkDriver(row) {
      this.driverToLink = null
      this.routeId = row.id
      this.routeData = row
      this.routeName = row.name
      this.showLinkDriver = true
    },

    openUnlinkDriver(row) {
      this.routeId = row.id
      this.driverToUnlink = row.driver_id
      this.showUnlinkDriver = true
    },

    confirmUnlink() {
      this.unlinkDriver()
      this.showUnlinkDriver = false
    },

    cancelUnlink() {
      this.showUnlinkDriver = false
    },

    cancelLink() {
      this.showLinkDriver = false
    },

    async linkDriver() {
      let data = {
        routeId: this.routeId,
        driverId: this.driverToLink.id,
        driverName: this.driverToLink.id
      }
      let res = await this.bindDriver(data)
      if (res) {
        this.showLinkDriver = false
        this.search()
      }
    },

    async unlinkDriver() {
      let data = {
        routeId: this.routeId,
        driverId: '',
      }
      let res = await this.bindDriver(data)
      if (res) {
        this.showUnlinkDriver = false
        this.search()
      }
    },

    captureParentStore(parentID){
      let parentName =  this.storesByGroup.filter((store) => {
        return store.id === parentID
      })
      return parentName[0].name
    },

    async previewRouteStores(el) {
      this.setLoading(true)
      this.showPreviewRouteStoresTable.data.content = []

      await routes.fetchStoresByRoute({ id:el.id }, (response) => {
        this.showPreviewRouteName = `${el.name}`
        this.showPreviewRouteStores = true
        this.showPreviewRouteStoresTable.data.content = response
      }, (e) => e)
      this.setLoading(false)
    },
  }
}
</script>

<style lang="scss" scoped>
.Routes {
  &__content {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: ". main .";
    grid-column-gap: 1rem;
  }

  &__table {
    grid-area: main;
    margin-bottom: 50px;
  }

  &__firstSearch {
    grid-area: main;
    margin-bottom: 50px;

    p {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      margin: 0;
      padding: 0;
      text-align: center;
      background: $gradient-primary;
    }
  }

  &__captions {
    display: flex;
    margin-top: 1rem;
    gap: 20px;
    &--item {
      font-size: $font-size-3xsmall;
      gap: 5px;
      display: flex;
    }
  }

  /deep/ [class~="insurance_status.display_name"] .DataTableTd__Content__Items {
    min-width: 135px;
    padding: 8px 12px;
  }

  .circle {
    height: 20px;
    width: 20px;
    border-radius: 100%;
    &-grey {
      background-color: $color-neutral-soft;
    }
    &-green {
      background-color: $color-success-soft;
    }
  }


.Organizer__filter{
    div{
      @media (max-width: $viewport-sm) {
        width: 100%;
        section:last-child{
          margin-top: 18px;
        }
      }
      &:first-child{
        height: 100%;
      }
      &:last-child{
        .BaseButton{
          margin-top: 18px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.Routes {
  .Organizer__filter{
    align-items: flex-end;
  }
  &__Modal {
    .Modal__container {
      height: auto;
      max-height: 90%;
      width: auto;
      max-width: 90%;
      overflow: initial;
      width: 100%;
      max-width: 600px;
    }
    .Modal__containerBody {
      height: auto;

      p {
        margin: 0 0 5px;
      }
    }
    .Modal__containerHeader {
      min-height: 32px;
      height: auto;
    }
  }
  &__ModalHeader {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 4px 3rem 4px 0.5rem;
  }
  &__ModalBody {
    padding: 1rem;
    height: auto;
    display: flex;
    flex-direction: column;

    .searchDrivers {
      margin-bottom: 20px !important;
    }

    .BaseButton {
      margin-top: 8px;
    }
  }
}
</style>