<template>
  <div class="SortingOrders">
    <Drawer
      ref="SortingOrders"
      class="SortingOrders"
      confirm-close
      confirm-close-title="sair da triagem"
      confirm-close-message=""
      confirm-close-button="Sair da Triagem"
      @close="closedDrawer"
      @confirmClose="confirmClose"
    >
      <template #confirmClose>
        <div
          v-if="remaining.content.length > 0 && remainingLoaded"
          class="SortingOrders__ConfirmClose"
        >
          <div class="SortingOrders__ConfirmClose__Message">
            <Icon name="exclamation-alt" color="yellow" /> Atenção: Existe rota(s) com pacotes sem bipar.
          </div>
          <div class="SortingOrders__ConfirmClose__Table">
            <DataTable
              :header="remaining.headers"
              :table="remaining.content"
            />
          </div>
        </div>
        <div v-if="remaining.content.length === 0 && remainingLoaded">
          <div class="SortingOrders__ConfirmClose__Message">
            <Icon name="check" color="green" /> Todos os pacotes das rotas iniciadas foram bipados!
          </div>
        </div>
      </template>

      <template #header>
        Triagem
      </template>

      <div v-if="!remainingByRoutePage" class="SortingOrders__Content">
        <div class="SortingOrders__Info" :class="infoClass">
          <p>
            <strong v-text="transitPointName" /><br>
            Utilize o leitor de código de barras para identificar a rota.
          </p>
          <BaseBarcodeAnimation
            :class="barcodeAnimationClass"
            :type="barcodeAnimationType"
          />
          <BaseInfoCard
            class="SortingOrders__InfoCard"
            title="identificação"
            no-items-message="aguardando bipagem"
            :theme="infoCard.theme"
            :icon="infoCard.icon"
            :icon-message="infoCard.iconMessage"
            :pill-message="infoCard.pillMessage"
            :items="infoCard.items"
          />
        </div>
        <BaseButton
          v-if="showHistory"
          icon="search"
          color="dark"
          label="pesquisar pacotes restantes"
          class="SortingOrders__RemainingButton"
          @click="openRemainingByRoutePage()"
        />
        <div
          v-if="showHistory"
          ref="history"
          class="SortingOrders__History"
        >
          <DataTable
            :featured="featuredDataTableItem"
            :header="history.headers"
            :table="history.content"
          />
        </div>
      </div>
      <div v-else class="SortingOrders__Content">
        <div class="SortingOrders__Content__RemainingHeader">
          <p>
            <strong v-text="transitPointName" />
          </p>
          <BaseButton
            theme="icon-left"
            icon="chevron-left-alt"
            color="dark"
            label="voltar"
            class="SortingOrders__BackButton"
            @click="remainingByRoutePage = false; fetchSortingHistory()"
          />
          <BaseTreeSelect
            v-model="remainingRouteSelected"
            name="selecionar rota"
            label="selecionar rota"
            placeholder="selecione"
            :select-options="remainingRoutes"
            :hide-select-all="true"
            @input="fetchSortingRemainingByRoute"
          />
        </div>
        <div v-if="showRemainingRouteOrders" class="SortingOrders__Content__RemainingTable">
          <div class="SortingOrders__Content__RemainingTable__Count">
            {{ remainingByRouteCount }}
          </div>
          <DataTable
            :header="remainingByRoute.headers"
            :table="remainingByRoute.content"
          />
        </div>
      </div>
    </Drawer>
    <Modal
      v-if="showLinkHubToUser"
      class="SortingOrders__LinkHubToUser"
      @close="showLinkHubToUser = false"
    >
      <template #header>
        Vincular ao hub
      </template>
      <template #body>
        <p>Para acessar a triagem, o pessoa operadora precisa ter um hub de exercício vinculado a sua conta.</p>
        <DynamicForm
          class="SortingOrders__LinkHubToUser__Form"
          :model="linkHubToUserForm"
          :blueprint="linkHubToUserFormBlueprint()"
          :endpoint-errors="linkHubToUserFormEndpointErrors"
          submit-type="json"
          @cancel="showLinkHubToUser = false"
          @submit="submitLinkHubToUser"
        />
      </template>
    </Modal>
  </div>
</template>
<script>
import { Drawer, DataTable, Modal, DynamicForm } from '@/components/molecules'
import { BaseInfoCard, BaseBarcodeAnimation, BaseButton, BaseTreeSelect } from '@/components/atoms'
import barCodeScannerMixin from '@/mixins/barCodeScanner'
import beepingSoundMixin from '@/mixins/beepingSound'
import { mapActions } from 'vuex'
import linkHubToUserFormBlueprint from './LinkHubToUserForm.blueprint'
import { routes, users } from '@/api'

export default {
  name: 'SortingOrders',
  components: {
    Modal,
    DynamicForm,
    Drawer,
    DataTable,
    BaseInfoCard,
    BaseBarcodeAnimation,
    BaseButton,
    BaseTreeSelect
  },
  mixins: [barCodeScannerMixin, beepingSoundMixin],
  data() {
    return {
      barcodeAnimationType: 'waiting',
      barcodeAnimationTimeout: null,
      infoCard: {
        id: null,
        theme: null,
        icon: null,
        iconMessage: null,
        pillMessage: null,
        items: []
      },
      history: {
        header: {},
        content: []
      },
      remaining: {
        header: {},
        content: []
      },
      remainingByRoute: {
        header: {},
        content: []
      },
      remainingByRoutePage: false,
      remainingRoutes: [],
      remainingRouteSelected: null,
      remainingLoaded: false,
      disconnectedTimeout: null,
      showLinkHubToUser: false,
      linkHubToUserForm: {
        transit_point: null
      },
      linkHubToUserFormEndpointErrors: {},
      transitPoint: null
    }
  },
  computed: {
    showRemainingRouteOrders() {
      return this.remainingByRoute.content.length > 0 && this.remainingRouteSelected
    },
    showHistory() {
      return this.history?.content?.length > 0
    },
    infoClass() {
      return {
        'SortingOrders__Content--shadow': this.showHistory
      }
    },
    barcodeAnimationClass() {
      return {
        'SortingOrders__BarcodeAnimation': true,
        'SortingOrders__BarcodeAnimation--hasHistory': this.showHistory
      }
    },
    featuredDataTableItem() {
      return this.infoCard.theme === 'notice' ? [this.infoCard.id] : []
    },

    remainingByRouteCount() {
      let count = this.remainingByRoute.content.length
      return `${count} pacote${count > 1 ? 's' : ''} aguardando bipagem:`
    },
    transitPointName() {
      return this.transitPoint?.name
    }
  },
  watch: {
  },
  destroyed() {
    this.stopListeningToRefreshSortingHistoryEvents()
  },
  methods: {
    linkHubToUserFormBlueprint,
    ...mapActions({
      setLoading: 'loading/setLoading',
      setAlert: 'error/setAlert',
    }),

    open() {
      this.reset()
      this.fetchSortingHistory()
    },

    confirmClose() {
      this.resetRemaining()
      this.fetchSortingRemaining()
    },

    closedDrawer() {
      this.stopListeningToRefreshSortingHistoryEvents()
      this.reset()
    },

    reset() {
      this.remainingByRoutePage = false
      this.showLinkHubToUser = false
      this.transitPoint = null
      this.resetInfoCard()
      this.resetHistory()
      this.resetRemaining()
    },

    resetInfoCard() {
      this.infoCard = {
        id: null,
        theme: null,
        icon: null,
        iconMessage: null,
        pillMessage: null,
        items: []
      }
    },

    resetHistory() {
      this.history = {
        header: {},
        content: []
      }
    },

    openRemainingByRoutePage() {
      this.remainingRoutes = []
      this.remainingByRoute.content = []
      this.remainingRouteSelected = null
      this.fetchSortingRemainingRoutes()
    },

    resetRemaining() {
      this.remainingLoaded = false
      this.remaining = {
        header: {},
        content: []
      }
    },

    startListeningToRefreshSortingHistoryEvents() {
      // Listen to Murisocket connection, channel and events
      this.$echo.channel(`order_sorting.${this.transitPoint?.id}`).listen('.order-beeped', (res) => {
        if (this.$refs?.SortingOrders?.show) {
          this.insertHistory(res)
        }
      })
      
      // If websocket doesn't have connection, run search method every 10 seconds
      this.disconnectedTimeout = setTimeout(() => {
        if (!window.echo?.connector?.socket?.connected) {
          if (this.$refs?.SortingOrders?.show) {
            this.fetchSortingHistory(false)
          }
        }
      }, 10 * 1000)
    },

    stopListeningToRefreshSortingHistoryEvents() {
      this.$echo.leave(`order_sorting.${this.transitPoint?.id}`)
      clearInterval(this.disconnectedTimeout)
    },

    animateBarcode(type, timeout = 2500) {
      clearTimeout(this.barcodeAnimationTimeout)
      this.barcodeAnimationType = type
      this.barcodeAnimationTimeout = setTimeout(() => {
        this.barcodeAnimationType = 'waiting'
      }, timeout)
    },

    submitLinkHubToUser(form) {
      this.setLoading(true)
      users.linkHubToUser({
        user_id: JSON.parse(localStorage.getItem('user')).id,
        body: {
          transit_point_id: form.transit_point.id
        }
      }, () => {
        this.open()
      }, (e) => this.linkHubToUserFormEndpointErrors = e.validation, () => this.setLoading(false))
      
    },

    async fetchSortingRemainingRoutes() {
      this.setLoading(true)
      routes.fetchHubRoutes((res) => {
        this.remainingRoutes = res
        this.remainingByRoutePage = true
      }, () => {
        this.remainingByRoutePage = false
      }, () => {
        this.setLoading(false)
      })
    },

    async fetchSortingRemaining() {
      this.setLoading(true)
      routes.fetchSortingHistory({ is_beeped: 0 }, (res) => {
        this.remaining = res.data
      }, (res) => {
        this.$refs.SortingOrders.confirmCloseDialog = false
        if (res.code === 403) {
          this.$refs.SortingOrders.close()
          this.stopListeningToRefreshSortingHistoryEvents()
          this.showLinkHubToUser = true
        } else {
          this.setAlert(res.message)
        }
      }, () => {
        this.remainingLoaded = true
        this.setLoading(false)
      })
    },

    async fetchSortingRemainingByRoute(route) {
      if (!route) return
      this.remainingByRoute.content = []

      this.setLoading(true)
      routes.fetchSortingHistory({ is_beeped: 0, route_id: route.id }, (res) => {
        this.remainingByRoute = res.data
      }, (res) => {
        this.$refs.SortingOrders.confirmCloseDialog = false
        if (res.code === 403) {
          this.$refs.SortingOrders.close()
          this.stopListeningToRefreshSortingHistoryEvents()
          this.showLinkHubToUser = true
        } else {
          this.setAlert(res.message)
        }
      }, () => {
        this.remainingLoaded = true
        this.setLoading(false)
      })
    },

    async fetchSortingHistory(loading = true) {
      if (loading) this.setLoading(true)
      routes.fetchSortingHistory({ is_beeped: 1 }, (res) => {
        this.history = res.data
        this.transitPoint = res.data?.transit_point
        this.$refs.SortingOrders.open()
        this.startListeningToRefreshSortingHistoryEvents()
      }, (res) => {
        this.$refs.SortingOrders.close()
        this.stopListeningToRefreshSortingHistoryEvents()
        if (res.code === 403) {
          this.showLinkHubToUser = true
        } else {
          this.setAlert(res.message)
        }
      }, () => this.setLoading(false))
    },

    async searchOrder(order_number) {
      this.setLoading(true)
      await routes.fetchOrderRoute({ order_number }, (res) => {
        this.insertContent(res)      
      }, (e) => e, () => this.setLoading(false))
    },

    onCodeSliced(order_number) {
      if (this.$refs?.SortingOrders?.show && !this.remainingByRoutePage) {
        // Checa se o pacote já existe na lista
        if (this.history.content.filter(p => p.order_number === order_number).length > 0) {
          // Se existe, mostra um card com o theme 'notice'
          let item = this.history.content.filter(p => p.order_number === order_number)?.[0]
          let res = {
            theme: 'notice',
            order_id: item.id,
            order_number: item.order_number,
            transporter_name: item.transporter_name,
            route_id: item.route_id,
            route_name: item.route_name,
            updated_at: item.updated_at,
          }
          this.insertContent(res)
        // Se não existe, pesquisa ele na api
        } else {
          this.searchOrder(order_number)
        }
      }
    },

    insertContent(res) {      
      let pillMessage = {
        'success': 'rota finalizada',
        'notice': 'pacote já foi bipado'
      }
      let icon = {
        'primary': 'package-delivery-alt',
        'success': 'check',
        'alert': 'close',
        'notice': 'report'
      }

      let total_orders = res.total_orders_in_route > 0 ? `${res.total_orders_sorted_in_route}/${res.total_orders_in_route} pacotes` : null
      let iconMessage = {
        'primary': total_orders,
        'success': total_orders,
      }
      let barcodeTheme = {
        'primary': 'success'
      }
      let beepingSound = {
        'primary': true,
        'success': true,
        'alert': false,
        'notice': false
      }

      this.infoCard = {
        id: res.order_id,
        theme: res.theme,
        icon: icon[res.theme] || null,
        iconMessage: iconMessage[res.theme] || null,
        pillMessage: pillMessage[res.theme] || null,
        items: res.theme !== 'alert' ? [
          { name: 'rota', value: res.route_name },
          { name: 'pacote', value: res.order_number },
          { name: 'transp.', value: res.transporter_name }
        ] : [
          { name: 'rota', value: res.message, theme: res.theme },
          { name: 'pacote', value: res.order_number },
        ]        
      }

      this.barcodeBeepingCheck(beepingSound[res.theme] || false)
      this.animateBarcode(barcodeTheme[res.theme] || res.theme)
      if (this.showHistory) this.scrollHistory(res.theme)
    },

    insertHistory(res) {
      if (this.history.content.filter(i => i.id === res.id).length === 0) {
        this.history.content.unshift(res)
      }
    },

    scrollHistory(theme) {
      setTimeout(() => {
        this.$refs.history.scroll({
          top: theme === 'notice' ? this.$refs.history.querySelectorAll('.DataTable__tr.DataTable__tr--featured')[0].offsetTop - 52 : 0,
          behavior: 'smooth'
        })
      }, 100)      
    }
  }
}
</script>
<style lang="scss" scoped>
.SortingOrders {

  &__LinkHubToUser {
    /deep/ .Modal__container {
      height: auto;
      max-height: calc(100% - 40px);
      width: calc(100% - 40px);
      max-width: 500px;
      overflow: initial;

      &Header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 $spacing-inset-small;
        border-radius: 8px 8px 0 0;
      }

      &Body {
        padding: $spacing-inset-small;
      }
    }
    &__Form {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-top: $spacing-inset-small;

      /deep/ .DynamicForm__ {
        &transit_point {
          width: 100%;
          margin: 0 0 $spacing-inset-1xsmall 0;
        }
        &cancel, &confirm {
          width: auto;
        }
      }
    }
  }

  &__Content {
    height: calc(100% + 48px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin: -$spacing-inset-medium;
    @media #{$mobile-view} {
      margin-top: 0;
      height: calc(100% + 24px);
    }

    &__Remaining {
      &Header {
        padding: $spacing-inset-medium;
      }
    }
  }

  &__Info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: $spacing-inset-medium;
    z-index: 1;
    background: $color-white;
    gap: $spacing-inset-medium;
    &--shadow {
      box-shadow: $shadow-high;
    }

    > p {
      width: 100%;
    }
  }

  &__BarcodeAnimation {
    max-width: 400px;
    display: block;

    &--hasHistory {
      @media #{$mobile-view} {
        display: none;
      }
    }
  }

  &__InfoCard {
    width: 100%;
  }

  &__RemainingButton, &__BackButton {
    margin: 0 $spacing-inset-medium $spacing-inset-medium auto;
    max-width: 250px;
    width: calc(100% - 48px);

    @media #{$mobile-view} {
      max-width: initial;
      margin: 0 auto $spacing-inset-medium;
    }
  }
  &__BackButton {
    max-width: 150px;
    margin: $spacing-inset-small auto $spacing-inset-medium 0;
  }

  &__History, &__Content__RemainingTable {
    background: #F0F0F6;
    padding: 0 $spacing-inset-medium $spacing-inset-medium;
    height: 100%;
    overflow: auto;
    flex: 1;
    position: relative;
    display: block;

    /deep/ .DataTable__th {
      position: sticky !important;
      padding-top: $spacing-inset-small;
      padding-bottom: $spacing-inset-small;
      top: 0;
      background: #F0F0F6;
    }

    /deep/ .DataTableWrapper {
      border: none;
    }

    /deep/ .DataTable thead .DataTable__tr td {
      background: none;
    }
  }

  &__Content__RemainingTable__Count {
    padding: $spacing-inset-medium $spacing-inset-small;
  }

  &__ConfirmClose {
    /deep/ .DataTableWrapper {
      border: none;
    }

    /deep/ .DataTable thead .DataTable__tr td {
      background: none;
    }

    &__Message {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      padding: 12px;
      .Icon {
        height: 16px;
      }
    }

    &__Table {
      max-height: calc(100vh - 220px);
      overflow: auto;
    }
  }
}
</style>
<style lang="scss">
.Drawer__ConfirmClose {
  max-width: 575px !important;
  .Dialog__Texts {
    width: calc(100% - 20px)
  }
  .transporter_name, .updated_at {
    display: none;
  }
}
</style>