<template>
  <Modal v-if="modal" class="RoutesHistory__Modal" @close="close">
    <template #header>
      <div class="RoutesHistory__Modal__Header">
        <span>Histórico de mudanças na rota <b>{{ data.name }}</b> </span>
      </div>
    </template>
    <template #body>
      <div class="RoutesHistory__Modal__Body">
        <DataTable
          :header="routeHistory.data.headers"
          :table="routeHistory.data.content"
        />
        <Pagination @page="search()" @paginate="search()" />
      </div>
    </template>
  </Modal>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { DataTable, Modal, Pagination } from '@/components/molecules'
export default {
  name: 'RoutesHistory',
  components: {
    DataTable,
    Modal,
    Pagination,
  },
  data() {
    return {
      modal: false,
      data: null
    }
  },
  computed: {
    ...mapState({
      routeHistory: state => state.routes.routeHistory,
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate,
    }),

  },
  watch: {
    data(val) {
      if (val) {
        this.search(val)
      }
    },
  },
  methods: {
    ...mapActions({
      fetchRouteHistory: 'routes/fetchRouteHistory',
      clearPagination: 'pagination/clearPagination',
      setPagination: 'pagination/setPagination',
      setError: 'error/setError'
    }),

    search() {
      const params = {
        obj: {
          route_id: this.data.id
        },
        paginate: this.paginate,
        page: this.page
      }
      this.fetchRouteHistory(params).then(() => this.setPagination(this.routeHistory))

    },

    //Handlers
    open(data) {
      this.modal = true
      this.data = data
      this.$emit('open')
    },

    close() {
      this.modal = false
      this.data = null
      this.$emit('close')
    },

  }
}
</script>
<style lang="scss" scoped>
.RoutesHistory {
  &__Modal {

    /deep/ .Modal__container {
      width: auto;
      height: auto;
      max-height: 90vh;
      min-width: 800px;
    }

    &__Header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;

      span {
        font-weight: $font-weight-bold;
        padding-left: 10px;
      }
    }

    &__Body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      padding: 1.5rem 1.5rem;
    }

  }
}
</style>
