<template>
  <Modal v-if="modal" class="RoutesErrors__Modal" @close="close">
    <template #header>
      <div class="RoutesErrors__Modal__Header">
        <span>Histórico de erros na rota <b>{{ data.name }}</b> </span>
      </div>
    </template>
    <template #body>
      <div class="RoutesErrors__Modal__Body">
        {{ erro }}
      </div>
    </template>
  </Modal>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { Modal } from '@/components/molecules'
export default {
  name: 'RoutesErrors',
  components: {
    Modal,
  },
  data() {
    return {
      modal: false,
      data: null
    }
  },
  computed: {
    ...mapState({
      routeErrors: state => state.routes.routeErrors,
    }),

    erro() {
      return this.routeErrors?.error
    }

  },
  watch: {
    data(val) {
      if (val) {
        this.search(val)
      }
    },
  },
  methods: {
    ...mapActions({
      fetchRouteErrors: 'routes/fetchRouteErrors',
    }),

    search() {
      this.fetchRouteErrors({id: this.data.id})
    },

    //Handlers
    open(data) {
      this.modal = true
      this.data = data
      this.$emit('open')
    },

    close() {
      this.modal = false
      this.data = null
      this.$emit('close')
    },

  }
}
</script>
<style lang="scss" scoped>
.RoutesErrors {
  &__Modal {

    /deep/ .Modal__container {
      width: auto;
      height: auto;
      max-height: 90vh;
      min-width: 800px;
    }

    &__Header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;

      span {
        font-weight: $font-weight-bold;
        padding-left: 10px;
      }
    }

    &__Body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      padding: 1.5rem 1.5rem;
    }

  }
}
</style>
