export default () =>
  [
    {
      id: 'transit_point',
      model: 'transit_point',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        label: 'HUB',
        noOptionsText: 'Nome do HUB',
        placeholder: 'Pesquisar HUBs',
        invalidLabel: 'Escolha um HUB',
        multiple: false,
        tags: true,
        preserveHeight: true,
        selectOptions: null,
        fetchOptions: 'stores/fetchHubsAsync'
      }
    },
    {
      id: 'cancel',
      tag: 'BaseButton',
      props: {
        label: 'cancelar',
      },
      events: {
        click: 'cancel'
      }
    },
    {
      id: 'confirm',
      tag: 'BaseButton',
      props: {
        label: 'vincular e ir para triagem',
        filled: true,
        color: 'primary-base',
        textColor: 'white',
        type: 'submit'
      }
    }
  ]